import React from 'react';
import './App.css';
import Copyright from "./components/Copyright";
import Footer from "./components/Footer";
import Bio from "./components/Bio";
import HeaderSmall from "./components/HeaderSmall";

function Biography() {

  return (
    <div className="App">
        <HeaderSmall headerText="About Jeroen Egelmeers" />

        <div className="clearfix"></div>

        <Bio />

        <Footer />
        <Copyright />
    </div>
  );
}

export default Biography;
