import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="border-b-2 border-gray-800 layout_padding lg:px-44">
            <nav className="flex justify-between max-container mx-auto">
                <span className="font-semibold whitespace-nowrap md:mr-20 font-bold px-8 py-4">Jeroen Egelmeers</span>
                <button
                    onClick={toggleMenu}
                    className="md:hidden pr-5 z-50 text-white"
                    aria-label="Toggle menu"
                >
                    <div className="relative w-6 h-6">
                        <Menu
                            className={`absolute inset-0 transition-opacity text-gray-900 duration-300 ease-in-out ${
                                isOpen ? 'opacity-0' : 'opacity-100'
                            }`}
                        />
                        <X
                            className={`absolute inset-0 transition-opacity text-gray-900 duration-300 ease-in-out ${
                                isOpen ? 'opacity-100' : 'opacity-0'
                            }`}
                        />
                    </div>
                </button>
                <div
                    className={`fixed inset-0 bg-white mt-14 flex items-center justify-center text-gray z-40 transition-transform duration-300 ease-in-out ${
                        isOpen ? 'translate-x-0' : 'translate-x-full'
                    } md:hidden`}
                >
                    <ul className="flex flex-col space-y-4 text-2xl">
                        <li className={`${location.pathname === "/" ? 'font-bold' : ''}`}>
                            <a href="/" className="block px-4">Home</a>
                        </li>
                        <li className={`${location.pathname === "/biography" ? "font-bold" : ""}`}>
                            <a href="/biography" className="block px-4">Biography</a>
                        </li>
                    </ul>
                </div>
                <ul className="flex justify-center uppercase text-gray-900 pt-4 pr-4 pb-[1px] title-font hidden md:flex">
                    <li className={`${location.pathname === "/" ? 'text-purple-900 mr-8' : 'mr-8'}`}>
                        <a href="/" className="block px-4">Home</a>
                    </li>
                    <li className={`${location.pathname === "/biography" ? "text-purple-900" : ""}`}>
                        <a href="/biography" className="block px-4">Biography</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Nav;