import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

const Blogs = () => {
    const blogs = [
        {
            title: "A guide to Adversarial Prompts",
            platform: "Sogeti Labs",
            url: "https://labs.sogeti.com/the-art-of-prompt-crafting/",
            date: "Sep 06, 2024",
            image: require("../assets/images/blog-pe.png"),
        },
        {
            title: "Prompt Libraries, Components and Recipes for Sustainable Prompt Engineering",
            platform: "Sogeti Labs",
            url: "https://labs.sogeti.com/libraries-components-and-recipes-for-sustainable-prompt-engineering/",
            date: "Mar 11, 2024",
            image: require("../assets/images/blog-pe.png"),
        },
        {
            title: "Prompt Engineering: Context window and prompting techniques",
            platform: "Sogeti Labs",
            url: "https://labs.sogeti.com/part-4-the-art-of-prompt-crafting-context-window-and-prompting-techniques/",
            date: "Feb 12, 2024",
            image: require("../assets/images/blog-pe.png"),
        }
    ]
    return (
        <div className="blog_section layout_padding mb-8">
            <div className="container">
                <h1 className="blog_taital bold">Latest blogs</h1>
                <p className="blog_text">Jeroen is writing for various platforms about Prompt Engineering. Read the latest blogs below!</p>

                <div className="mt-10">
                    <div className="flex flex-col md:flex-row justify-center mx-5 space-x-0 md:space-x-5 items-center">
                        {blogs.map((blog, index) => (
                            <div key={index} className={"max-w-[210px] mb-10 bg-black"}>
                                <a href={blog.url} target={"_blank"} title={blog.title} className={"relative block hover:shadow-lg hover:shadow-white"}>
                                    <img src={blog.image} className={"opacity-60"} />

                                    <div className="blog-text font-bold absolute top-[35%] left-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                        {blog.title}
                                    </div>

                                    <div className="absolute top-0 left-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                        {blog.platform &&
                                            <div className="bg-purple-950 text-white p-2 rounded">{blog.platform}</div>
                                        }
                                    </div>

                                    <div className={`absolute bottom-0 right-0 p-2 w-40 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br bg-black text-white`}>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                        <div className={"text-sm"}>{blog.date}</div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blogs;