const AboutMe = () => {
    return(
        <div className="layout_padding px-12 lg:px-44 mb-16 bg-gray-300">
            <div className="flex max-container mx-auto">
                <div className={"pb-20"}>
                    <div className="about_taital_main">
                        <h1 className="about_taital">About <strong>Jeroen Egelmeers</strong></h1>
                        <p className="">Jeroen Egelmeers is a Prompt Engineering Advocate and GenAI Whisperer at Sogeti Netherlands. Jeroen also serves as a Software Engineering Trainer at the Capgemini Academy. He frequently delivers presentations on Java, Software Quality and Prompt Engineering at various events and venues. Jeroen is the founder of the "Crafting AI Prompts Framework." This framework is designed to craft optimal prompts while considering non-disclosure, data security, GDPR, and other constraints.</p>
                        <div className="readmore_bt"><a href="/biography">Read More</a></div>
                    </div>
                </div>
                <div className="self-end hidden md:block">
                    <div className={""}><img src={require("../assets/images/1651682643858.png")} className={"mt-10 min-w-[420px]"} /></div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;