import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
    const events = [
        {
            date: "Sep 05, 2024",
            title: "ODSC",
            url: "https://odsc.com/speakers/supercharge-your-productivity-mastering-prompt-engineering/",
            image: require("../assets/images/img-5.png"),
            live: false,
        },
        {
            date: "Sep 24, 2024",
            title: "QXDay 2024",
            url: "https://www.sogeti.nl/events/qxday2024",
            image: require("../assets/images/img-3.png"),
            live: true,
            soldOut: false
        },
        {
            date: "Oct 07, 2024",
            title: "Devoxx Belgium",
            url: "https://www.devoxx.be/schedule/speaker-details/?id=4381",
            image: require("../assets/images/img-1.png"),
            live: true,
            soldOut: true
        }
    ]

    function isThisWeek(date: string) {
        const eventDate = new Date(date);
        const today = new Date();
        const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
        return eventDate >= today && eventDate < nextWeek;
    }

    function pastEvent(date: string) {
        return new Date(date) < new Date();
    }

    return(
        <div className="services_section layout_padding">
            <div className="container">
                <h1 className="services_taital mb-6 text-[30px] md:text-[40px]">Upcoming events</h1>
                <p className="services_text px-6">Jeroen is actively speaking at conferences and events. Below is a list of upcoming events where you can find Jeroen speaking!</p>
                <div className="services_section_2">
                    <div className="flex flex-col md:flex-row justify-center mx-5 space-x-0 md:space-x-5 items-center">
                        {events.map((event, index) => (
                            <div key={index} className={"max-w-[310px] mb-10 roundedn"}>
                                <a href={event.url} target={"_blank"} className={"block relative rounded-xl hover:shadow-lg hover:shadow-black event-button"}>
                                    <img src={event.image} className={`${new Date(event.date) < new Date() ? 'opacity-50' : ''}`} />

                                    <div className={`absolute top-0 left-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 rounded-tl rounded-br`}>
                                        {event.live ?
                                            <div className={`bg-purple-950 text-white p-2 rounded`}>In-person</div> :
                                            <div className={`bg-green-500 text-white p-2 rounded`}>Online</div>
                                        }
                                    </div>

                                    {(event.soldOut) &&
                                        <div
                                            className="absolute top-0 right-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                            <div className="bg-red-600 text-white p-2 rounded">Sold out!</div>
                                        </div>
                                    }

                                    {pastEvent(event.date) ?
                                        <div
                                            className="absolute bottom-0 left-0 text-white px-2 py-7 h-12 justify-center flex items-center space-x-2 bg-opacity-80">
                                            <div className="bg-red-600 text-white rounded-tl rounded-lg p-2 opacity-80">Event concluded</div>
                                        </div> :
                                        isThisWeek(event.date) &&
                                        <div
                                            className="absolute bottom-0 left-0 text-white px-2 py-7 h-12 justify-center flex items-center space-x-2 bg-opacity-80">
                                            <div className="bg-green-600 text-white rounded-tl rounded-lg p-2 rounded opacity-80">This week!</div>
                                        </div>
                                    }

                                    <div className="absolute top-0 left-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                        {event.live ?
                                            <div className="bg-purple-950 text-white p-2 rounded">In-person</div> :
                                            <div className="bg-green-500 text-white p-2 rounded">Online</div>
                                        }
                                    </div>

                                    <div className={`absolute bottom-2 right-1 p-2 w-[48%] h-10 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-lg bg-black ${pastEvent(event.date) ? 'text-gray-500' : 'text-white'}`}>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                        <div className={"text-sm"}>{event.date}</div>
                                    </div>

                                    <div className="btn_main"><span>{event.title}</span></div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;